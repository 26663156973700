<script setup lang="ts">
const time = ref("");
const timezone = ref("");

const updateClock = () => {
  const now = new Date();
  time.value = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
};

let interval;

onMounted(() => {
  updateClock();
  interval = setInterval(updateClock, 1000);
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <div class="flex flex-col items-center justify-center mx-auto">
    <div class="text-md sm:text-xl font-semibold text-gray-800">{{ time }}</div>
    <div class="text-1.5xs sm:text-xs text-gray-500">{{ timezone }}</div>
  </div>
</template>

<style scoped>

</style>